import React, { useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import AppRouter from "./AppRouter";
import AuthRoute from "../routes/AuthRoute";
import AuthRouter from "./AuthRouter";
import PrivateRoute from "../routes/PrivateRoute";
import { Context } from "../../context/Store";
import SectionLoader from "../../includes/loader/SectionLoader";

function MainRouter() {
  const { dispatch } = useContext(Context);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    let user_data = localStorage.getItem("user_data");
    user_data = JSON.parse(user_data);
    dispatch({ type: "UPDATE_USER_DATA", user_data: user_data });
    setTimeout(() => setLoading(false), 1100);
  }, []);
  return isLoading ? (
    <SectionLoader />
  ) : (
    <Routes>
      <Route
        path="auth/*"
        element={
          <AuthRoute>
            <AuthRouter />
          </AuthRoute>
        }
      />
      <Route 
        path="/*"
        element={
          <PrivateRoute>
            <AppRouter />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}

export default MainRouter;
