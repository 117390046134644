import React, { useContext, useEffect, useState } from "react";
import HeadTopBar from "../../includes/HeadTopBar";
import { styled } from "styled-components";

import instance from '../../../axios/axios';
import AddBlogModal from "../../modal/blog/AddBlogModal";
import EditBlogModal from "../../modal/blog/EditBlogModal";
import Nodata from "../../includes/noData/Nodata";
import { listBlogApi } from "../../../axios/api";
import CircularProgress from "@mui/material/CircularProgress";


function Blogs() {
  const [isModal, setModal] = useState(false);
  const [editModal, seteditModal] = useState(false);
  const [editObj, seteditObj] = useState({});

  const [blogs, setblogs] = useState(null);



  useEffect(() => {
    if(!editModal){
      seteditObj('');
    }
  }, [editModal]);

  useEffect(() => {
     instance
     .get(listBlogApi)
      .then((response)=>{
        const { StatusCode, data } = response.data.app_data;
        if(StatusCode === 6000){
          if(data.length === 0){
             setblogs([])
          }else{
            setblogs(data)
          }
        }else{
          setblogs([])
        }
      })
  }, [isModal,editModal]);

  return (
    <Container>
      <Wrapper className="wrapper">
        <HeadTopBar />
        <ProgramSection>
          <TopSection>
            <Title>Blogs</Title>
            <Button
              onClick={() => {
                setModal(true);
              }}
            >
              Add Blog
            </Button>
          </TopSection>
          <Cards>
          {
            blogs === null ? 
            <Loader>
              <CircularProgress/>
            </Loader> 
            : (
            blogs.length === 0 ? (
                <Nodata/>
              ):(
                blogs.map((obj, index) => {
                  return (
                    <Card>
                      <Number>{index + 1} </Number>
                      <TextBar>
                        <span> {obj.title}</span>
                      </TextBar>
                      <Image>
                        <img src={obj.image} alt={""} />
                      </Image>
                      <Body>
                          {
                            obj.slug
                          }
                      </Body>
                      <Edit 
                      onClick={()=>{
                        seteditObj(obj);
                        seteditModal(true)
                      }}
                      >
                        Edit
                      </Edit>
                    </Card>
                  );
                })
              )
            )
          }

          
          </Cards>
        </ProgramSection>
        <AddBlogModal isModal={isModal} setModal={setModal}/>

        <EditBlogModal isModal={editModal} setModal={seteditModal} item={editObj}/>
      </Wrapper>
    </Container>
  );
}

export default Blogs;

const Container = styled.div`
  padding: 80px 0;
  color: var(--textcolor);
`;
const Wrapper = styled.div``;
const ProgramSection = styled.div`
  margin-top: 40px;
`;
const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const Title = styled.h3`
  color: #fff;
`;
const Button = styled.div`
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  color: var(--lightblue);
  height: 40px;
  width: 150px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Cards = styled.div``;
const Card = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 10px;
`;
const Number = styled.div`
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  width: 10%;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
`;
const TextBar = styled.div`
  display: flex;
  width: 20%;
  padding: 0 40px;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
`;
const Icon = styled.div`
  width: 18px;
  cursor: pointer;
`;
const Edit = styled.div`
  background-color: var(--darkgrey);
  border-radius: 8px;
  border: 1px solid var(--bordercolor);
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--lightblue);
`;

const Image = styled.div`
  width: 30%;
  background-color: var(--darkgrey);
  border-radius: 8px;
  border: 1px solid var(--bordercolor);
  padding: 10px 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 60%;
    display: block;
  }
`;
const Body = styled.div`
  display: flex;
  width: 20%;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  text-align: center;
`;

const CardTitles = styled.div`
  display: flex;
  /* justify-content: space-between; */
  gap: 10px;
  margin-bottom: 20px;
`;
const CardTit = styled.div`
  background-color: ${({ type }) =>
    type == "em"
      ? "transparent"
      : type == "ems"
      ? "transparent"
      : "var(--darkgrey)"};
  border: ${({ type }) =>
    type == "em"
      ? "none"
      : type == "ems"
      ? "none"
      : "1px solid var(--bordercolor)"};

  width: ${({ type }) =>
    type == "sl"
      ? "85px"
      : type == "name"
      ? "220px"
      : type == "user"
      ? "40%"
      : type == "phone"
      ? "180px"
      : type == "em"
      ? "85px"
      : type == "ems"
      ? "45px"
      : ""};
  display: flex;
  height: 40px;
  font-size: 14px;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0px 0 20px;
  box-sizing: border-box;
`;

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;
`;
