import React, { useContext, useState } from "react";
import { navList } from "../../general/array";
import logo from "../../../assets/images/logo/logo.png";
import { styled } from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../context/Store";


function Sidebar() {
  const {
    state: { user_data },
  } = useContext(Context);

  const isActive = user_data.active_menu;
  const navigate = useNavigate();

  const handleNavigate = (url) => {
      navigate(url);
  }; 

  return (
    <Aside>
      <Logo onClick={()=>handleNavigate('/')}  >
        <img src={logo} alt="" />
      </Logo>
      <MainTitle to="/">
        <LogoMain>
          <i className="ri-home-4-line"></i>
        </LogoMain>
        <Span className={isActive == "Dashboard" && "active"}>Dashboard</Span>
      </MainTitle>
      <NavList>
        {navList.map((item, index) => (
          <Nav to={item.route} key={index}>
            <Icon>
              {/* {isActive == item.title ? (
                <img src={item.blue_image} alt={item.title} />
              ) : (
                <img src={item.image} alt={item.title} />
              )} */}
              <img src={item.image} alt={item.title} />
            </Icon>
            <Text className={isActive == item.title && "active"}>
              {item.title}
            </Text>
          </Nav>
        ))}
      </NavList>
    </Aside>
  );
}

export default Sidebar;

const Aside = styled.aside`
  background-color: #1b1d21;
  height: 100vh;
  overflow: scroll;
  box-sizing: border-box;
  padding: 40px;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Logo = styled.div`
  width: 65%;
  margin: 0 auto;
  margin-bottom: 50px;
`;
const MainTitle = styled(Link)`
  background-color: #232327;
  padding: 6px 10px;
  display: flex;
  text-decoration: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border: 2px solid #2b2d30;
  border-radius: 20px;
  margin-bottom: 30px;
`;
const LogoMain = styled.div`
  color: #ffffff97;
  font-size: 20px;
  margin-right: 30px;
  position: relative;
  &::after {
    position: absolute;
    content: "|";
    right: -18px;
    color: #2b2d30;
  }
`;
const Span = styled.div`
  color: #ffffff8e;
  &.active {
    color: var(--lightblue);
  }
`;
const NavList = styled.div`
  padding: 16px;
`;
const Nav = styled(Link)`
  display: flex;
  text-decoration: none;
  cursor: pointer;
  height: 60px;
  position: relative;
  &::after {
    position: absolute;
    width: 100%;
    background-color: #2b2d30;
    content: "";
    height: 1px;
    bottom: 18px;
  }
`;
const Icon = styled.div`
  margin-right: 10px;
  width: 20px;
  color: #ffffff8e;
`;
const Text = styled.div`
  color: #ffffff8e;
  transition: 0.4s ease;
  font-size: 14px;
  &.active {
    color: var(--lightblue);
    font-size: 16px;
    transition: 0.4s ease;
    @media all and (max-width: 1440px) {
      font-size: 14px;
    }
  }
`;
