import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import instance from "../../../axios/axios";
import { addBannerApi, addProjectApi } from "../../../axios/api";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";


function AddProjectModal({ isModal, setModal, type, id }) {
  const FileFields = ['plot_image','mobile_videos','computer_videos','project_logo','walkthrough_videos','master_plan',
                     'nearby_location','brochure']
  const initalState = {
    projectname:'',
    plot_image:'',
    mobile_videos:'',
    computer_videos:'',
    iframeurl:'',
    project_logo:'',
    walkthrough_videos:'',
    master_plan:'',
    about:'',
    nearby_location:'',
    map_location_url:'',
    location:'',
    totalplots:'',
    soldplot:0,
    status:'ongoing',
    cent:'',
    rera_number:'',
    feature:'',
    meta_tag:'',
    meta_description:'',
    brochure:'',
    slug:''
  }
   const [formData, setformData] = useState(initalState);
   const [error, seterror] = useState("");
   const [loader, setloader] = useState(false);
   let validationError = false;

  // -----------------Add program------------------------
  const assignHandler = () => {
     const form = new FormData();

      Object.entries(formData).forEach((obj)=>{
        
        if(obj[1]===""){
          
          validationError = true
          seterror(`${obj[0]} - this field is required`);
          setTimeout(() => {
            seterror("");
          }, 3000);
        }else{
          form.append(obj[0],obj[1]);
        }
      })
      
      if(!validationError){
        setloader(true)
        instance.post(addProjectApi,form)
        .then((response)=>{
          const { StatusCode , data} = response.data.app_data;
          setloader(true)
          if(StatusCode === 6000){
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Project Added !',
              showConfirmButton: false,
              timer: 1500,
              width:600,
            })
            setformData(initalState);
            setModal(false);
          }else if(StatusCode === 6001){
              seterror(data.message)
              setTimeout(() => {
                seterror("");
              },3000);
          }
        })
      }
  };

  const handleChange = (e) =>{
       const { name} = e.target;
       validationError=false;
       
       if(!FileFields.includes(name)){
        setformData({
            ...formData,
            [name] : e.target.value
          })
       }else{
        setformData({
            ...formData,
            [name] : e.target.files[0]
          })
       }
  };

  return (
    <Container className={isModal && "active"}>
      <Overlay onClick={() => setModal(false)}></Overlay>
      <Modal>
        <Containers>
          <Cover>
            <Label>Project Name</Label>
            <Input
              type="text"
              placeholder="Enter Project name"
              onChange={(e)=>handleChange(e)}
              name={"projectname"}
              value={formData.projectname}
            />
          </Cover>
          <Cover>
            <Label>About</Label>
            <TextArea
              name={"about"}
              onChange={(e)=>handleChange(e)}
              value={formData.about}
            ></TextArea>
          </Cover>
          <Cover>
            <Label>Banner Video -Laptop (1920 x 1080)</Label>
            <CoverInput>
              <Input
                type="file"
                name={"computer_videos"}
                onChange={(e)=>handleChange(e)}
              />
            </CoverInput>
          </Cover>
          <Cover>
            <Label>Banner Video - Mobile <br /> (1080 x 1200)</Label>
            <CoverInput>
              <Input
                type="file"
                name={"mobile_videos"}
                onChange={(e)=>handleChange(e)}
              />
            </CoverInput>
          </Cover>
          <Cover>
            <Label>360 iframe url</Label>
            <TextArea
              name="iframeurl"
              onChange={(e)=>handleChange(e)}
              value={formData.iframeurl}
            ></TextArea>
          </Cover>
          <Cover>
            <Label>project Logo <br /> (2363 x 2246)</Label>
            <CoverInput>
              <Input
                type="file"
                name={"project_logo"}
                onChange={(e)=>handleChange(e)}
              />
            </CoverInput>
          </Cover>
          <Cover>
            <Label>walkthrough Video <br /> (720 x 406)</Label>
            <CoverInput>
              <Input
                type="file"
                name={"walkthrough_videos"}
                onChange={(e)=>handleChange(e)}
              />
            </CoverInput>
          </Cover>
          <Cover>
            <Label>Master Plan Image <br /> (1106 x 825)</Label>
            <CoverInput>
              <Input
                type="file"
                name={"master_plan"}
                onChange={(e)=>handleChange(e)}
              />
            </CoverInput>
          </Cover>
          <Cover>
            <Label>Nearby Location Image <br /> (1256 x 1298)</Label>
            <CoverInput>
              <Input
                type="file"
                name={"nearby_location"}
                onChange={(e)=>handleChange(e)}
              />
            </CoverInput>
          </Cover>
          <Cover>
            <Label>Project Image <br /> (423 x 365)</Label>
            <CoverInput>
              <Input
                type="file"
                name={"plot_image"}
                onChange={(e)=>handleChange(e)}
              />
            </CoverInput>
          </Cover>
          <Cover>
            <Label>Project Location Map iframe code</Label>
            <TextArea
              name={"map_location_url"}
              onChange={(e)=>handleChange(e)}
              value={formData.map_location_url}
            ></TextArea>
          </Cover>
          <Cover>
            <Label>Project Location</Label>
            <Input
              type="text"
              placeholder="Enter the Project location and district by seperating with a ',' "
              onChange={(e)=>handleChange(e)}
              name={"location"}
              value={formData.location}
            />
          </Cover>
          <Cover>
            <Label>Total Plots</Label>
            <Input
              type="number"
              placeholder="Enter Total Plots count"
              onChange={(e)=>handleChange(e)}
              name={"totalplots"}
              value={formData.totalplots}
            />
          </Cover>
          {/* <Cover>
            <Label>Sold Plots</Label>
            <Input
              type="number"
              placeholder="Enter Sold Plots count"
              onChange={(e)=>handleChange(e)}
              name={"soldplot"}
              value={formData.soldplot}
            />
          </Cover> */}
          <Cover>
            <Label>Brochure</Label>
            <CoverInput>
              <Input
                type="file"
                name={"brochure"}
                onChange={(e)=>handleChange(e)}
              />
            </CoverInput>
          </Cover>
          <Cover>
            <Label>Cent range</Label>
            <Input
              type="text"
              placeholder="Enter cent range"
              onChange={(e)=>handleChange(e)}
              name={"cent"}
              value={formData.cent}
            />
          </Cover>
          <Cover>
            <Label>Rera Number</Label>
            <Input
              type="text"
              placeholder="Enter Rera Number"
              onChange={(e)=>handleChange(e)}
              name={"rera_number"}
              value={formData.rera_number}
            />
          </Cover>
          <Cover>
            <Label>Feature</Label>
            <Input
              type="text"
              placeholder="Enter Feature"
              onChange={(e)=>handleChange(e)}
              name={"feature"}
              value={formData.feature}
            />
          </Cover>
          <Cover>
            <Label>meta tag</Label>
            <Input
              type="text"
              placeholder="Enter meta tag"
              onChange={(e)=>handleChange(e)}
              name={"meta_tag"}
              value={formData.meta_tag}
            />
          </Cover>
          <Cover>
            <Label>meta description</Label>
            <Input
              type="text"
              placeholder="Enter meta description"
              onChange={(e)=>handleChange(e)}
              name={"meta_description"}
              value={formData.meta_description}
            />
          </Cover>
          <Cover>
            <Label>Url - slug</Label>
            <Input
              type="text"
              placeholder="Enter url slug"
              onChange={(e)=>handleChange(e)}
              name={"slug"}
              value={formData.slug}
            />
          </Cover>
          {/* <Cover>
            <Label>Status</Label>
            <Select name="status" onChange={(e)=>handleChange(e) }>
                <option selected value="ongoing">Ongoing</option>
                <option value="completed">Completed</option>
            </Select>
          </Cover> */}


          <Covers>
            <Submit
              onClick={() => {
                assignHandler();
              }}
            >
              {
                loader ? <CircularProgress/> : "Submit"
              }
            </Submit>
          </Covers>
          {
            error && <Error>{error}</Error>
          }
        </Containers>
      </Modal>
    </Container>
  );
}
export default AddProjectModal;

const Container = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
  &.active {
    transform: scale(1, 1);
    backdrop-filter: blur(4px);
  }
`;
const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 1000;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 90%;
  max-width: 736px;
  max-height: 100vh;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
  z-index: 1000;
  background: #232327;
  border-radius: 5px;
  overflow-y: hidden;
  box-shadow: 0px 3px 56px #000;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  @media all and (max-width: 1380px) {
    left: 58%;
  }
  @media all and (max-width: 1280px) {
    width: 550px;
  }
  @media all and (max-width: 1080px) {
    width: 500px;
  }
  @media all and (max-width: 980px) {
    width: 450px;
  }
  @media all and (max-width: 768px) {
    width: 400px;
  }
  @media all and (max-width: 640px) {
    width: 350px;
  }
  @media all and (max-width: 480px) {
    width: 330px;
  }
  @media all and (max-width: 360px) {
    width: 300px;
  }
`;
const Select = styled.select`
  padding: 10px 20px;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;
const CoverInput = styled.div`
  width: 100%;
`;
const Containers = styled.div`
  padding: 50px 80px;
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  border-radius: 10px;
  @media all and (max-width: 640px) {
    padding: 20px;
  }
`;

const Covers = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: end;
`;
const Cover = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
`;
const Label = styled.div`
  color: var(--textcolor);
  width: 40%;
`;
const Input = styled.input`
  padding: 10px 20px;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;
const TextArea = styled.textarea`
  padding: 10px 20px;
  width: 100%;
  height: 120px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;
const Submit = styled.div`
  background-color: var(--lightblue);
  color: #000;
  width: 150px;
  height: 40px;
  font-family: "mont-medium";
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    width: 25px !important;
    height: 25px !important;
    color: white;
  }
`;
const Sub = styled.div`
  display: flex;
  width: 60%;
`;

const Error = styled.p`
    padding: 0;
    margin: 10px 0;
    color: var(--red);
    width: 100%;
    text-align: center;
`;

