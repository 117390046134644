import React, { createContext, useReducer } from "react";
import Reducer from "./Reducer";

const initialState = {
  user_data: {
    isLogin: false,
    access_token: "",
    refresh_token:"",
    active_menu: "",
  },
  master_plan : {
    active : false,
    points :{
      top:0,
      left:0,
      rotate:0
    },
    currentPoint:null,
    allpoints:[],
    activeTab:"DeskTop",
    arrows:false,
    arrowPoints : {
      mobile_points: "[1,2]",
      desktop_points: "[2,3]",
      tab_points: "[2,3]",
    },
    state:false
  }
};
const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

export const Context = createContext(initialState);

export default Store;
