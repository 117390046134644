import React, { useContext, useState } from "react";
import { navList } from "../../general/array";
import logo from "../../../assets/images/logo/logo.png";
import { styled } from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../context/Store";
import { TOGGLE_STATE, UPDATE_ARROW_STATE, UPDATE_CURRENT_POINT, UPDATE_LEFT_POINT, UPDATE_TOP_POINT, UPDATE_ROTATE_POINT } from "../../context/types";
import instance from "../../../axios/axios";
import { updateMasterPlanArrows } from "../../../axios/api";
import Swal from "sweetalert2";


function MasterPlanSideBar() {
  const {
    state: { user_data,master_plan },dispatch
  } = useContext(Context);

  const { top, left, rotate } = master_plan.points;
  const allpoints = master_plan.allpoints;
  const Points = master_plan.allpoints;
  const currentPoint = master_plan.currentPoint;
  const active = master_plan.activeTab;
  const arrows = master_plan.arrows;
  const arrowPoints = master_plan.arrowPoints;
  const navigate = useNavigate();
  
  const handleSlider = (type,value)=>{
        if(type === "top"){
           dispatch({
            type:UPDATE_TOP_POINT,
            payload: value
           })
        }else if (type === "left"){
          dispatch({
            type:UPDATE_LEFT_POINT,
            payload: value
          })
        }else{
          dispatch({
            type:UPDATE_ROTATE_POINT,
            payload: value
          })
        }
  }

  const handleInput = (type,value)=>{

    if(type === "top"){
        if(value === ""){
            value =  0
        }else if(value > 100){
            value = 100
        }else if(value < 0){
            value = 0
        }

        dispatch({
         type:UPDATE_TOP_POINT,
         payload: value
        })
     }else if(type === "left"){
        if(value === ""){
            value =  0
        }else if(value > 100){
            value = 100
        }else if(value < 0){
            value = 0
        }
         dispatch({
             type:UPDATE_LEFT_POINT,
             payload: value
            })
     }else {
      if(value === "" || value === undefined){
          value =  222
      }else if(value > 360){
          value = 360
      }else if(value < 0){
          value = 0
      }
       dispatch({
           type:UPDATE_ROTATE_POINT,
           payload: value
          })
   }
  }

  const handleChange = (e)=>{
    const { value } = e.target;
    dispatch({
      type: UPDATE_ARROW_STATE,
      payload:false
    })

    if(value === currentPoint){
            dispatch({
              type:UPDATE_CURRENT_POINT,
              payload:null
            })
      }else{
        dispatch({
          type:UPDATE_CURRENT_POINT,
          payload:value
        })
        }
        if(active === "DeskTop"){
          dispatch({type:UPDATE_TOP_POINT,payload:JSON.parse(Points[value].desktop_points)[0]})
          dispatch({type:UPDATE_LEFT_POINT,payload:JSON.parse(Points[value].desktop_points)[1]})
        }else if(active === "Tablet"){
          dispatch({type:UPDATE_TOP_POINT,payload:JSON.parse(Points[value].tab_points)[0]})
          dispatch({type:UPDATE_LEFT_POINT,payload:JSON.parse(Points[value].tab_points)[1]})
        }else if(active === "Mobile"){
          dispatch({type:UPDATE_TOP_POINT,payload:JSON.parse(Points[value].mobile_points)[0]})
          dispatch({type:UPDATE_LEFT_POINT,payload:JSON.parse(Points[value].mobile_points)[1]})
        }
  };


  const handleNavigate = (url) => {
      navigate(url);
  }; 

  const  handleArrowClick = () => {
        dispatch({
          type:UPDATE_CURRENT_POINT,
          payload: null
        })

        dispatch({
          type: UPDATE_ARROW_STATE,
          payload:null
        })
        if(!arrows){
          if(active === "DeskTop"){
            dispatch({type:UPDATE_TOP_POINT,payload:JSON.parse(arrowPoints.desktop_points)[0]})
            dispatch({type:UPDATE_LEFT_POINT,payload:JSON.parse(arrowPoints.desktop_points)[1]})
            dispatch({type:UPDATE_ROTATE_POINT,payload:JSON.parse(arrowPoints.desktop_points)[2]})
          }else if(active === "Tablet"){
            dispatch({type:UPDATE_TOP_POINT,payload:JSON.parse(arrowPoints.tab_points)[0]})
            dispatch({type:UPDATE_LEFT_POINT,payload:JSON.parse(arrowPoints.tab_points)[1]})
            dispatch({type:UPDATE_ROTATE_POINT,payload:JSON.parse(arrowPoints.tab_points)[2]})
          }else if(active === "Mobile"){
            dispatch({type:UPDATE_TOP_POINT,payload:JSON.parse(arrowPoints.mobile_points)[0]})
            dispatch({type:UPDATE_LEFT_POINT,payload:JSON.parse(arrowPoints.mobile_points)[1]})
            dispatch({type:UPDATE_ROTATE_POINT,payload:JSON.parse(arrowPoints.mobile_points)[2]})
          }
        }else{
          dispatch({type:UPDATE_TOP_POINT,payload:0})
          dispatch({type:UPDATE_LEFT_POINT,payload:0})
          dispatch({type:UPDATE_ROTATE_POINT,payload:0})
        }
        
  };

  const handleArrowSubmit = () =>{
      instance.post(updateMasterPlanArrows+arrowPoints.project,arrowPoints)
      .then((response)=>{
          const { StatusCode , data } = response.data.app_data;
          if(StatusCode === 6000){
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Arrow Updated !',
              showConfirmButton: false,
              timer: 1500,
              width:600,
            })
            dispatch({
              type:TOGGLE_STATE,
              payload:null
            })
          }
      })
  };
 
  return (
    <Aside>
      <Logo onClick={()=>handleNavigate('/')} >
        <img src={logo} alt="" />
      </Logo>
      
      <NavList>
          <Label >Position:</Label>

          <Slider>
            <Label>
                Top : <Value onChange={(e)=>handleInput("top",e.target.value)} type="number" value={top} />
            </Label>
              <input value={top} onChange={(e)=>handleSlider("top",e.target.value)} type="range" step={1} max={100} min={1} />
         </Slider>
          
         <Slider>
           <Label>
                Left : <Value onChange={(e)=>handleInput("left",e.target.value)} type="number" value={left} />
            </Label>
              <input value={left} onChange={(e)=>handleSlider("left",e.target.value)} type="range" step={1} max={100} min={1} />
        </Slider>

        {arrows &&<Slider>
           <Label>
                Rotate : <Value onChange={(e)=>handleInput("rotate",e.target.value)} type="number" value={rotate} />
            </Label>
              <input value={rotate} onChange={(e)=>handleSlider("rotate",e.target.value)} type="range" step={1} max={360} min={1} />
        </Slider>}

        <Label>Points :</Label>

        <PointsList>
            <Select onChange={handleChange} >
               <Option selected disabled>
                  choose Point
               </Option>
               {
                allpoints.map((obj,indx)=>{
                  return (
                  <Option selected={currentPoint === null ? false : parseInt(currentPoint) === indx} value={indx}>
                    {obj.name}
                  </Option>
                  )
                })
               }
            </Select>
        </PointsList>

        <Arrow onClick={handleArrowClick}  status={arrows} >
          Arrow
        </Arrow>

        {
          arrows && 
          <SaveButton onClick={handleArrowSubmit}>
            Save
          </SaveButton>
        }
         
      </NavList>
    </Aside>
  );
}

export default MasterPlanSideBar;

const Aside = styled.aside`
  background-color: #1b1d21;
  height: 100vh;
  overflow: scroll;
  box-sizing: border-box;
  padding: 20px;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Logo = styled.div`
  width: 65%;
  margin: 0 auto;
  margin-bottom: 50px;
`;

const NavList = styled.div`
  padding: 16px;
`;

const Slider = styled.div`
width:100%;
display:flex;
flex-direction: column;
input{
    width: 100%;
}
`;

const Label = styled.p`
     display: flex;
     align-items:center;
     justify-content: flex-start;
`;

const Value = styled.input`
      width:30% !important;
      padding:10px;
      background-color:transparent;
      border:1px solid var(--lightblue);
      border-radius:10px;
      margin: 0 5px;
      color:#fff;
      caret-color:#fff;
`;


const PointsList = styled.div`
    display:flex;
`;

const Select = styled.select`
    width:100%;
    padding:15px;
    border:1px solid var(--bordercolor);
    border-radius:5px;
    background-color:var(--darkgrey);
    color:var(--textcolor);
`;

const Option = styled.option`
      padding:10px;
      height:20px;
      background-color:var(--darkgrey);
`;

const Arrow = styled.button`
    border:1px solid var(--textcolor);
    color: #fff;
    padding:10px;
    border-radius: 5px;
    background-color: var(--darkgrey);
    width:100%;
    margin: 15px auto;

    ${(props)=>props.status && `
          background-color:var(--lightblue);
    `}
`;

const SaveButton = styled.button`
    color: #fff;
    padding:10px;
    border-radius: 5px;
    background-color:var(--red);
    width:100%;
    margin: 15px auto;
    border: none !important;
    cursor:pointer;
`;



