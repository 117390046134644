import React, { useContext, useEffect, useState } from "react";
import HeadTopBar from "../../includes/HeadTopBar";
import { styled } from "styled-components";

import instance from '../../../axios/axios';
import { useNavigate, useParams, } from "react-router-dom";
import plan from '../projects/Master_Plan.png';
import { Context } from "../../context/Store";
import { SETPOINTS, SET_ARROW_POINTS, ToggleMasterPlanStatus, UPDATE_ACTIVE_TAB, UPDATE_ARROW_STATE, UPDATE_CURRENT_POINT, UPDATE_LEFT_POINT, UPDATE_ROTATE_POINT, UPDATE_TOP_POINT } from "../../context/types";
import AddMasterPlanPointModal from "../../modal/masterplan/AddMasterPlanPointModal";
import { deleteMasterPlanPoint, editMasterPlanPoint, listMasterPlanArrows, listMasterPlanPoints, viewSingleProjectApi } from "../../../axios/api";
import Swal from "sweetalert2";



function MasterPlanPoints() {
  
  // const [active, setactive] = useState("DeskTop");
  const [modal, setmodal] = useState(false);
  const [name, setname] = useState('');
  const [img, setimg] = useState(null);
  const [state, setstate] = useState(false);
  const [plan, setplan] = useState('');

  const { state:data , dispatch} = useContext(Context);

  const { top , left,rotate} = data.master_plan.points;
  const Points = data.master_plan.allpoints;
  const currentPoint = data.master_plan.currentPoint;
  const active = data.master_plan.activeTab;
  const arrowPoints = data.master_plan.arrowPoints;
  const arrow = data.master_plan.arrows;
  const arrowStatus = data.master_plan.state;
  // const [currentPoint, setcurrentPoint] = useState(null);
  // const [Points, setPoints] = useState([{top:0,left:0},{top:1,left:1},{top:2,left:2}]);

  const {id } = useParams();
  const navigate = useNavigate();

  
  
  useEffect(() => {
    instance
    .get(listMasterPlanPoints+id)
     .then((response)=>{
       const { StatusCode, data } = response.data.app_data;
       if(StatusCode === 6000){
       if(data.length === 0){
           dispatch({
            type:SETPOINTS,
            payload:[],
           })
       }else{

        dispatch({
          type:SETPOINTS,
          payload:data,
         })
       }
       
       }else{

        dispatch({
          type:SETPOINTS,
          payload:[],
        })

       }
     })

     instance
    .get(viewSingleProjectApi+id)
     .then((response)=>{
       const { StatusCode, data } = response.data.app_data;
       if(StatusCode === 6000){
       const item = data[0];
       
       if(data.length === 0){
           setplan('')
       }else{
          setplan(item?.master_plan)
       }
        
       }else{
        setplan('')
       }
     })

 }, [modal,state]);

 useEffect(() => {
  instance
  .get(listMasterPlanArrows+id)
   .then((response)=>{
     const { StatusCode, data } = response.data.app_data;
     if(StatusCode === 6000){

     const item = {
      ...data[0],
      project:id
     }
     
     if(data.length === 0){
        
     }else{
        dispatch({
          type:SET_ARROW_POINTS,
          payload:item
        })
     }
      
     }else{
       const obj = {
        mobile_points: "[95,0]",
        desktop_points: "[95,0]",
        tab_points: "[95,0]",
        project:id
      }
      dispatch({
        type:SET_ARROW_POINTS,
        payload:obj
      })
     }
   })
 }, [arrowStatus])


  useEffect(() => {
    dispatch({
      type:ToggleMasterPlanStatus,payload:null
    })
  return ()=>{
    dispatch({
      type:ToggleMasterPlanStatus,payload:null
    })
  }
  }, []);


  useEffect(() => {
    if(currentPoint !== null){
      const convertedValue = parseInt(currentPoint);
      const newPoints = Points?.map((obj,index)=>{
        if(index === convertedValue){
          if(active === "DeskTop"){
            const newpoints = [top,left]
            let newOne = {
              ...obj,
              desktop_points:JSON.stringify(newpoints)
            }
            return newOne;
          }else if(active === "Tablet"){
            const newpoints = [top,left]
            let newOne = {
              ...obj,
              tab_points:JSON.stringify(newpoints)
            }
            return newOne;
          }else if(active === "Mobile"){
            const newpoints = [top,left]
            let newOne = {
              ...obj,
              mobile_points:JSON.stringify(newpoints)
            }
            return newOne;
          }
        }else{
          return obj;
        }
      })
      dispatch({
        type:SETPOINTS,
        payload:newPoints
      })
    }
    
    if(arrow){
      let newObj = {};
      if(active === "DeskTop"){
        const newpoints = [top,left,rotate]
         const temp = {
          ...arrowPoints,
          desktop_points:JSON.stringify(newpoints)
         }
         newObj = temp;

      }else if(active === "Tablet"){
        const newpoints = [top,left,rotate]
        const temp = {
         ...arrowPoints,
         tab_points:JSON.stringify(newpoints)
        }
        newObj = temp;
        
      }else if(active === "Mobile"){
        const newpoints = [top,left,rotate]
        const temp = {
         ...arrowPoints,
         mobile_points:JSON.stringify(newpoints)
        }
        newObj = temp;
      }
      dispatch({
        type:SET_ARROW_POINTS,
        payload:newObj
      })
    }
  }, [top,left,rotate])

  useEffect(() => {
    if(currentPoint === null){
      dispatch({type:UPDATE_LEFT_POINT,payload:0})
      dispatch({type:UPDATE_TOP_POINT,payload:0})
    }else{

      if(active === "DeskTop"){
        dispatch({type:UPDATE_TOP_POINT,payload:JSON.parse(Points[currentPoint].desktop_points)[0]})
        dispatch({type:UPDATE_LEFT_POINT,payload:JSON.parse(Points[currentPoint].desktop_points)[1]})
      }else if(active === "Tablet"){
        dispatch({type:UPDATE_TOP_POINT,payload:JSON.parse(Points[currentPoint].tab_points)[0]})
        dispatch({type:UPDATE_LEFT_POINT,payload:JSON.parse(Points[currentPoint].tab_points)[1]})
      }else if(active === "Mobile"){
        dispatch({type:UPDATE_TOP_POINT,payload:JSON.parse(Points[currentPoint].mobile_points)[0]})
        dispatch({type:UPDATE_LEFT_POINT,payload:JSON.parse(Points[currentPoint].mobile_points)[1]})
      }
    }

    if(arrow){
      if(active === "DeskTop"){
        dispatch({type:UPDATE_TOP_POINT,payload:JSON.parse(arrowPoints.desktop_points)[0]})
        dispatch({type:UPDATE_LEFT_POINT,payload:JSON.parse(arrowPoints.desktop_points)[1]})
        dispatch({type:UPDATE_ROTATE_POINT,payload:JSON.parse(arrowPoints.desktop_points)[2]})
      }else if(active === "Tablet"){
        dispatch({type:UPDATE_TOP_POINT,payload:JSON.parse(arrowPoints.tab_points)[0]})
        dispatch({type:UPDATE_LEFT_POINT,payload:JSON.parse(arrowPoints.tab_points)[1]})
        dispatch({type:UPDATE_ROTATE_POINT,payload:JSON.parse(arrowPoints.tab_points)[2]})
      }else if(active === "Mobile"){
        dispatch({type:UPDATE_TOP_POINT,payload:JSON.parse(arrowPoints.mobile_points)[0]})
        dispatch({type:UPDATE_LEFT_POINT,payload:JSON.parse(arrowPoints.mobile_points)[1]})
        dispatch({type:UPDATE_ROTATE_POINT,payload:JSON.parse(arrowPoints.mobile_points)[2]})
      }
    }else{
      dispatch({type:UPDATE_TOP_POINT,payload:0})
      dispatch({type:UPDATE_LEFT_POINT,payload:0})
      dispatch({type:UPDATE_ROTATE_POINT,payload:0})
    }

    
  },[active])

  useEffect(() => {
    if(currentPoint !== null){
      setname(Points[currentPoint].name)
    }
  },[currentPoint])

  // const handleNavigate = (id) => {
  //   navigate('/edit-project/'+id);
  // };

  const handleChangeActive = (menu)=>{
    dispatch({
      type:UPDATE_ACTIVE_TAB,
      payload:menu
    })
  };

  const setCurrentPointToNull = ()=>{
      dispatch({
        type:UPDATE_CURRENT_POINT,
        payload:null
        })
  };



  const handleClickOfPoint  = (value)=>{
    dispatch({
      type: UPDATE_ARROW_STATE,
      payload:false
    })
    
    if(currentPoint === null){
      if(active === "DeskTop"){
        dispatch({type:UPDATE_TOP_POINT,payload:JSON.parse(Points[value].desktop_points)[0]})
        dispatch({type:UPDATE_LEFT_POINT,payload:JSON.parse(Points[value].desktop_points)[1]})
      }else if(active === "Tablet"){
        dispatch({type:UPDATE_TOP_POINT,payload:JSON.parse(Points[value].tab_points)[0]})
        dispatch({type:UPDATE_LEFT_POINT,payload:JSON.parse(Points[value].tab_points)[1]})
      }else if(active === "Mobile"){
        dispatch({type:UPDATE_TOP_POINT,payload:JSON.parse(Points[value].mobile_points)[0]})
        dispatch({type:UPDATE_LEFT_POINT,payload:JSON.parse(Points[value].mobile_points)[1]})
      }
      
        dispatch({
        type:UPDATE_CURRENT_POINT,
        payload:value
      })

    }else if(currentPoint === value){
      dispatch({type:UPDATE_LEFT_POINT,payload:0})
      dispatch({type:UPDATE_TOP_POINT,payload:0})

      dispatch({
        type:UPDATE_CURRENT_POINT,
        payload:null
      })
    }else{
      if(active === "DeskTop"){
        dispatch({type:UPDATE_TOP_POINT,payload:JSON.parse(Points[value].desktop_points)[0]})
        dispatch({type:UPDATE_LEFT_POINT,payload:JSON.parse(Points[value].desktop_points)[1]})
      }else if(active === "Tablet"){
        dispatch({type:UPDATE_TOP_POINT,payload:JSON.parse(Points[value].tab_points)[0]})
        dispatch({type:UPDATE_LEFT_POINT,payload:JSON.parse(Points[value].tab_points)[1]})
      }else if(active === "Mobile"){
        dispatch({type:UPDATE_TOP_POINT,payload:JSON.parse(Points[value].mobile_points)[0]})
        dispatch({type:UPDATE_LEFT_POINT,payload:JSON.parse(Points[value].mobile_points)[1]})
      }
      dispatch({
        type:UPDATE_CURRENT_POINT,
        payload:value
      })
    }
  };

  const handleUpdate = () =>{
    let editedData = Points[currentPoint]
    editedData = {
      ...editedData,
      name:name,
      "image": img === undefined ? null : img
    }
    const editedObj = new FormData()
    Object.entries(editedData).map((obj)=>{
        editedObj.append(obj[0],obj[1]);
    })
    instance
    .put(editMasterPlanPoint+editedData.id,editedObj)
     .then((response)=>{
       const { StatusCode, data } = response.data.app_data;
       if(StatusCode === 6000){
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Point Upadated !',
          showConfirmButton: false,
          timer: 1500,
          width:600,
        })
        setCurrentPointToNull();
        setstate(!state)

       }else{
          
       }
     })
  };

  const handleDelete = () =>{
    Swal.fire({
      title: 'Do you want to delete the point?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const temp = Points[currentPoint]
        instance.delete(deleteMasterPlanPoint+temp.id)
        .then((response)=>{
          const { StatusCode , data} = response.data.app_data;
          if(StatusCode === 6000){
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Point Deleted !',
              showConfirmButton: false,
              timer: 1500,
              width:600,
            })
            setstate(!state)

            dispatch({
              type:UPDATE_CURRENT_POINT,
              payload:null
            })

          }else if(StatusCode === 6001){
              // seterror(data.Message)
              // setTimeout(() => {
              //   seterror("");
              // },3000);
          }
        })
      }
    })
  };

  const handleChange = (e)=>{
    const { name} = e.target;
    if(name === 'image'){
      setimg(e.target.files[0])
    }else{
      setname(e.target.value)
    }

  }

  const goBack = ()=>{
      navigate('/edit-project/'+id)
  };

  return (
    <Container className="ct">
      <Wrapper className="wrapper">
        <HeadTopBar />
        <ProgramSection>
          <TopSection>
            <Title>Master plan points <p onClick={goBack} >Back</p> </Title>
          </TopSection>
          <MiddleSection>
            <MasterPlanArea>

              <EditOption> 
                  <Top>
                  <p>Master Plan Details</p>
                  <AddPoint onClick={()=>setmodal(true)}>
                    Add Point
                  </AddPoint>
                  
                  </Top>

                   {
                    currentPoint !== null && (
                    <BottomSide>
                      <ImgPreview>
                          {
                            Points[currentPoint].image === null ? <img src={Points[currentPoint].amenities_image} alt="" /> : <img src={Points[currentPoint].image} alt="" />
                          }
                          
                          <input name="image" onChange={handleChange}  type="file"  id="name" className="point-name" />
                      </ImgPreview>
                      <RightSide>
                          <label htmlFor="name">Name</label>
                          <input name="name" onChange={handleChange}  type="text" value={name}  id="name" className="point-name"/>
                          
                          <UpdateBtn>
                               <button onClick={handleUpdate} >
                                  Update
                               </button>
                          </UpdateBtn>
                          <DeleteBtn>
                              <button onClick={handleDelete} >
                                  Delete
                              </button>
                          </DeleteBtn>

                      </RightSide>
                    </BottomSide>
                    )
                   }

              </EditOption >
              <Tabs>
                <Tab  active={active === "DeskTop"? true : false} onClick={()=>handleChangeActive('DeskTop')}>
                    DeskTop
                </Tab>
                <Tab active={active === "Tablet"? true : false}  onClick={()=>handleChangeActive('Tablet')}>
                    Tablet
                </Tab>
                <Tab active={active === "Mobile"? true : false}  onClick={()=>handleChangeActive('Mobile')}>
                    Mobile
                </Tab>
            </Tabs>

                {
                    active === "DeskTop" ? 
                    (
                      <Desktop>
                         <PlanPreview  type="Desktop">
                           <div>
                           
                            {
                              Points?.map((obj,indx)=>{
                                return(
                                  <Point onClick={()=>handleClickOfPoint(indx)} in={indx}  c={parseInt(currentPoint)}   clicked={indx === parseInt(currentPoint)}  top={JSON.parse(obj.desktop_points)[0]} left={JSON.parse(obj.desktop_points)[1]}>
                                   +
                                  </Point>
                                )
                              })
                            }

                            <img src={plan} alt="" />
                            <Arrow top={JSON.parse(arrowPoints.desktop_points)[0]} left={JSON.parse(arrowPoints.desktop_points)[1]} rotate={JSON.parse(arrowPoints.desktop_points)[2]}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </Arrow>
                           </div>
                        </PlanPreview>
                      </Desktop>
                    ): active === "Tablet" ? (
                        <Tablet>
                          <PlanPreview type="Tablet">
                            <div>
                            {
                              Points?.map((obj,indx)=>{
                                return(
                                  <Point onClick={()=>handleClickOfPoint(indx)}  clicked={indx === parseInt(currentPoint)}  top={JSON.parse(obj.tab_points)[0]} left={JSON.parse(obj.tab_points)[1]}>
                                   +
                                  </Point>
                                )
                              })
                            }
                            <img src={plan} alt="" />
                            <Arrow top={JSON.parse(arrowPoints.tab_points)[0]} left={JSON.parse(arrowPoints.tab_points)[1]} rotate={JSON.parse(arrowPoints.tab_points)[2]}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </Arrow>
                            </div>
                         </PlanPreview> 
                        </Tablet>
                    ):(
                        <Mobile>
                          <PlanPreview type="Mobile">
                            <div>
                            {
                              Points?.map((obj,indx)=>{
                                return(
                                  <Point onClick={()=>handleClickOfPoint(indx)} clicked={indx === parseInt(currentPoint)}  top={JSON.parse(obj.mobile_points)[0]} left={JSON.parse(obj.mobile_points)[1]}>
                                   +
                                  </Point>
                                )
                              })
                            }
                            <img src={plan} alt="" />
                            <Arrow top={JSON.parse(arrowPoints.mobile_points)[0]} left={JSON.parse(arrowPoints.mobile_points)[1]} rotate={JSON.parse(arrowPoints.mobile_points)[2]}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </Arrow>
                            </div>
                          </PlanPreview> 
                        </Mobile>
                    )
                }
            </MasterPlanArea>
          </MiddleSection>
        </ProgramSection>
        <AddMasterPlanPointModal id={id}  isModal={modal} setModal={setmodal}/>
      </Wrapper>
    </Container>
  );
}

export default MasterPlanPoints;

const Container = styled.div`
  padding: 80px 0;
  color: var(--textcolor);
`;
const Wrapper = styled.div`
   width:96% !important;
`;
const ProgramSection = styled.div`
  margin-top: 40px;
`;
const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const Title = styled.h3`
  color: #fff;
  display: flex;
  align-items: center;
  p{
    font-size:14px;
    color: var(--lightblue);
    text-align:center;
    margin-left: 10px;
    text-decoration: underline;
    cursor: pointer;
  }
`;
const Button = styled.div`
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  color: var(--lightblue);
  height: 40px;
  width: 150px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Cards = styled.div``;
const Card = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 10px;
`;
const Number = styled.div`
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  width: 10%;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
`;
const TextBar = styled.div`
  display: flex;
  width: 20%;
  padding: 0 40px;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
`;
const Icon = styled.div`
  width: 18px;
  cursor: pointer;
`;
const Edit = styled.div`
  background-color: var(--darkgrey);
  border-radius: 8px;
  border: 1px solid var(--bordercolor);
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--lightblue);
`;

const Image = styled.div`
  width: 30%;
  background-color: var(--darkgrey);
  border-radius: 8px;
  border: 1px solid var(--bordercolor);
  padding: 10px 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 60%;
    display: block;
  }
`;
const Status = styled.div`
  width: 20%;
  border-radius: 8px;
  border: 1px solid var(--bordercolor);
  padding: 10px 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props)=> props.active === true ? 'background-color: var(--lightblue);color:#fff;' : 'background-color: var(--darkgrey);color:var(--lightblue);'};
  cursor: pointer;
`;

const MiddleSection = styled.div`
      width: 100%;
      padding:10px;
`;

const Table = styled.table`
   width: 100%;
   border-radius: 10px;
`;

const Thead = styled.thead`
   background-color:#5a5d61;
   color: #fff;
   padding:5px;
`;

const Tr = styled.tr`
   border-radius: 5px;
   cursor: pointer;
   background-color:#5e5e5e;
   &:hover{
    background-color:#757575;
    }
`;

const Th = styled.th`
    font-size:16px;
    text-transform: capitalize;
    padding:15px;
`;

const Tbody = styled.tbody`
   color: #fff;
`;

const Td = styled.td`
   text-align: center;
   padding:8px;
   ${
    (props)=>props.img && 
                       `
                        display: flex;
                        align-items: center;
                        justify-content: center;
                       `
   }

   img{
      width:200px;
      display: block;
   }

   i{
    color:var(--red);
   }
`;

const Tabs = styled.div`
  width:100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Tab = styled.div`
background-color: var(--darkgrey);
color: var(--lightblue);
padding:20px;
display: flex;
align-items: center;
justify-content:center;
border-radius: 10px;
border: 1px solid #434343;
${(props)=>props.active && ` 
        background-color:var(--lightblue);
        color:#fff;
    `}
width:170px;
`;


const MasterPlanArea =styled.div``;

const Desktop = styled.div`
 padding: 20px;
 display: flex;
 flex-direction: column;
 width: 100%;
 height: 100%;
`;

const Tablet = styled.div`
   padding: 20px;
   display: flex;
   width: 100%;
   height: 100%;
`;

const Mobile = styled.div`
   padding: 20px;
   display: flex;
   width: 100%;
   height: 100%;
`;

const PlanPreview = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 100%;
height: auto;
max-height: 825px;
div{
    background-color: #ffffff;
    width: auto;
    ${(props)=>props.type === "Desktop"? "width:auto;": props.type === "Tablet"? "max-width:768px;":"max-width:576px;"}
    position: relative;
   }
img{
    display: block;
    max-width: 100%;
    height: auto;
}

`;

const Point = styled.button`
    ${(props)=>{
      return props.clicked ? "background-color: blue;":"background-color: var(--red);"
      } }
    color:#fff;
    width:15px;
    height:15px;
    position: absolute;
    border:none;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content:center;
    border-radius: 15px;
    ${(props)=>{
        return (
            `top: ${props.top}%;
             left: ${props.left}%;`
        )
    }}
`;

const BottomSection = styled.div`
 width:100%;
 margin-top:20px;
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: column;
 gap:20px;
 input{
    width:80%;
 }
`;

const EditOption = styled.div`
      display: flex;
      padding: 15px 25px;
      margin: 15px auto;
      border: 1px solid #3c3c3c;
      width:95%;
      flex-direction: column;
      border-radius: 10px;
`;

const Top = styled.div`
    background-color:#4747478e;
    margin-bottom:10px;
    border-radius: 5px;
    display: flex;
     p{
      color: #ffffff;
      text-align: center;
      font-size:18px;
      width:80%;
     } 
`;

const BottomSide = styled.div`
      display: flex;
      width: 100%;
`;


const ImgPreview  = styled.div`
  width:50%;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  img{
    width:100%;
    height:250px;
    background-color: #fff;
  }

  input{
    margin: 10px 0;
  }
`;

const RightSide = styled.div`
      width:50%;
      background-color: transparent;
      padding:5px 20px;
      border-radius: 5px;
      display:flex;
      flex-direction:column;
      gap:10px;
      input{
        height:45px;
        background-color:var(--darkgrey);
        border: 1px solid #fff;
        width:50%;
        border-radius:5px;
        color:#fff;
        padding-left: 10px;
        outline:none;
        &::placeholder{
          color:#fff;
        }

      }

      label{
        color:#ffffff;
      }
`;

const UpdateBtn = styled.div`
      width:52%;
      padding:10px 0px;
      margin-top:20px;
      button{
        background-color:var(--lightblue);
        color:#fff;
        padding:10px 25px;
        outline:none;
        border:none;
        width:150px;
        border-radius:5px;
        float: right;
      }
`;


const AddPoint = styled.button`
      background-color:var(--lightblue);
      color:#fff;
      padding: 15px 30px;
      float: right;
      border:none;
      height:40px;
      margin: auto 0;
      border-radius: 5px;
`;

const DeleteBtn = styled.div`
      width:52%;
      padding:0px 0px;
      margin-top:0px;
      button{
        background-color:var(--red);
        color:#fff;
        padding:10px 25px;
        outline:none;
        border:none;
        width:150px;
        border-radius:5px;
        float: right;
      }
`;

const Arrow = styled.div`
    position: absolute !important;
    transform: rotate(225deg);
    cursor: pointer;
    z-index: 9;
    /* background: transparent url(../img/arrows.png) no-repeat 0 0; */
    width: 70px !important;
    height: 100px;
    display: flex;
    align-items:center;
    justify-content: center;
    flex-direction: column;
    background:transparent !important;
    ${(props)=>{
        return (
            `top: ${props.top}%;
             left: ${props.left}%;
             transform: rotate(${props.rotate}deg);`
        )
    }}

    span{
      display: block;
      width: 1.5vw;
      height: 1.5vw;
      border-bottom: 4px solid rgb(0, 0, 255);
      border-right: 4px solid rgb(0, 0, 255);
      transform: rotate(45deg);
      margin: -14px;
      animation: animate 2s infinite;
      &:nth-child(2){
        animation-delay: -0.2s;
      }

      &:nth-child(3){
        animation-delay: -0.4s;
      }

      @keyframes animate {
        0% {
          opacity: 0;
          transform: rotate(45deg) translate(-20px, -20px);
        }
        50% {
          opacity: 1;
        }
        100% {
          opacity: 0;
          transform: rotate(45deg) translate(20px, 20px);
        }
      }
      
  }
`;