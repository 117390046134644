import React, { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../../screens/login/Login";
import Dashboard from "../../screens/dashboard/Dashboard";
import Sidebar from "../../includes/sidebar/Sidebar";
import { styled } from "styled-components";
import Testimonials from "../../screens/testimonials/Testimonials";
import Enquiry from "../../screens/enquiry/Enquiry";
import Banners from "../../screens/banners/Banners";
import Home from "../../screens/homeSection/Home";
import BlogList from "../../screens/blog/BlogList";
import Contact from "../../screens/contact/Contact";
import About from "../../screens/about/About";
import Awards from "../../screens/awards/Awards";
import ProjectList from "../../screens/projects/ProjectList";
import EditProject from "../../screens/projects/EditProject";
import AmenitiesList from "../../screens/projects/AmenitiesList";
import MasterPlanPoints from "../../screens/projects/MasterPlanPoints";
import MasterPlanSideBar from "../../includes/sidebar/MasterPlanSideBar";
import { Context } from "../../context/Store";

function AppRouter() {
  const { state} = useContext(Context);
  const { active } = state?.master_plan;
  
  return (
    <Container>
      <Left>
        {
          active ? <MasterPlanSideBar/> : <Sidebar />
        }
      </Left>
      <Right>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/banners" element={<Banners/>} />
          <Route path="/home" element={<Home />} />
          <Route path="/blog" element={<BlogList />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/enquiry" element={<Enquiry />} />
          <Route path="/awards" element={<Awards />} />

          <Route path="/projects" element={<ProjectList />} />
          <Route path="/edit-project/:id" element={<EditProject />} />
          <Route path="/amenities/:id" element={<AmenitiesList />} />
          <Route path="/master-plan-points/:id" element={<MasterPlanPoints />} />
          <Route path="/test" element={<MasterPlanPoints />} />

          <Route path="/login" element={<Login />} />

        </Routes>
      </Right>
    </Container>
  );
}

export default AppRouter;
const Container = styled.div`
  height: 100vh;
  background: #232327;
  color: #fff;
  display: flex;
`;
const Left = styled.div`
  width: 16%;
  height: 100%;
  @media all and (max-width: 1440px) {
    width: 20%;
  }
`;
const Right = styled.div`
  width: 86%;
  max-height: 100vh;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;
