import React, { useEffect, useState } from "react";
import styled from "styled-components";
import HeadTopBar from "../../includes/HeadTopBar";
import instance from "../../../axios/axios";
import { editContactDetails, viewContactDetails } from "../../../axios/api";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";


function Contact() {
  const [formData, setformData] = useState({});
  const [state, setstate] = useState(false);
  const [obj, setobj] = useState(null);
  
  useEffect(() => {
    instance.get(viewContactDetails)
    .then((response)=>{
      const { StatusCode,data} = response.data.app_data;
      if(StatusCode === 6000){
        setformData(data);
        setobj(data);
      }
    })
  }, [state])

  const handleChange = (e) => {
      const { name} = e.target;
        setformData({
          ...formData,
          [name]:e.target.value
        })
  }

  const onSubmit = (e) =>{
    const form = new FormData();
      Object.entries(formData).forEach((obj)=>{
        form.append(obj[0],obj[1]);
      })
    instance.post(editContactDetails,form,{
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((response)=>{
      const { StatusCode, data } = response.data.app_data;
      if(StatusCode === 6000){
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Contact Info Edited!',
          showConfirmButton: false,
          timer: 1500,
          height:100,
          width:400,
          fontSize:"14px",
        })
          setformData({})
          setstate(!state)
      }else{
        
      }

    })
  };

  return (
    <Container>
      <Wrapper className="wrapper">
        <HeadTopBar />
        <ProgramSection>
          <TopSection>
            <Title>Contact - Content</Title>
          </TopSection>
          {
            obj === null ?
            <Loader>
              <CircularProgress/>
            </Loader>
            : (
              <Section>
              <Cover>
                  <Label>Title</Label>
                  <Input type="text"
                   placeholder="Enter the Title"
                   value={formData?.title}
                   name="title"
                   onChange={(e)=>handleChange(e)}
                   />
                </Cover>
              <Cover>
                  <Label>Content</Label>
                  <TextArea placeholder="Address"
                  value={formData?.content}
                  name="content"
                  onChange={(e)=>handleChange(e)}
                  ></TextArea>
                </Cover>
                <Cover>
                  <Label>Email</Label>
                  <Input type="text"
                   placeholder="Enter the email"
                   value={formData?.email}
                   name="email"
                   onChange={(e)=>handleChange(e)}
                   />
                </Cover>
                <Cover>
                  <Label>Phone Number</Label>
                  <Input type="text"
                   placeholder="Enter the Phone Number .Don't include +91"
                   value={formData?.phone}
                   name="phone"
                   onChange={(e)=>handleChange(e)}
                   />
                </Cover>
                <Cover>
                  <Label>Phone Number 2</Label>
                  <Input type="text"
                   placeholder="Enter the Phone Number.Don't include +91"
                   value={formData?.phone2}
                   name="phone2"
                   onChange={(e)=>handleChange(e)}
                    />
                </Cover>
                <Cover>
                  <Label>Address</Label>
                  <TextArea placeholder="Address"
                  value={formData?.address}
                  name="address"
                  onChange={(e)=>handleChange(e)}
                  ></TextArea>
                </Cover>
                <Cover>
                  <Label>Office Map</Label>
                  <TextArea placeholder="Office Map iframe code"
                   value={formData?.map}
                   name="map"
                   onChange={(e)=>handleChange(e)}
                  ></TextArea>
                </Cover>
                <Cover>
                  <Label>Facebook Url</Label>
                  <Input type="text" 
                  name="facebook_url"
                  placeholder="Enter the FaceBook Url"
                  value={formData?.facebook_url}
                  onChange={(e)=>handleChange(e)}
                   />
                </Cover>
                <Cover>
                  <Label>Youtube Url</Label>
                  <Input type="text" placeholder="Enter the Youtube Url"
                  value={formData?.youtube_url}
                  name="youtube_url"
                  onChange={(e)=>handleChange(e)}
                   />
                </Cover>
                <Cover>
                  <Label>Instagram Url</Label>
                  <Input type="text" placeholder="Enter the Instagram Url"
                  value={formData?.instagram_url}
                  name="instagram_url"
                  onChange={(e)=>handleChange(e)}
                   />
                </Cover>
                <Cover>
                  <Label>Whatsapp Number</Label>
                  <Input type="text" placeholder="Enter the Whatsapp Number.Don't include +91"
                  value={formData?.whatsapp_url}
                  name="whatsapp_url"
                  onChange={(e)=>handleChange(e)}
                   />
                </Cover>
                <Submit>
                    <SubmitButton 
                    onClick={onSubmit}
                    >
                        Update
                    </SubmitButton>
                </Submit>
              </Section>
            )
          }
          
        </ProgramSection>
      </Wrapper>
    </Container>
  );
}

export default Contact;

const Container = styled.div`
  padding: 80px 0;
  color: var(--textcolor);
`;
const Wrapper = styled.div``;

const ProgramSection = styled.div`
  margin-top: 40px;
`;
const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;
const Title = styled.h3`
  color: #fff;
`;
const Button = styled.div`
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  color: var(--lightblue);
  height: 40px;
  width: 150px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Cards = styled.div``;
const Card = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 10px;
`;
const Number = styled.div`
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  width: 10%;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
`;
const TextBar = styled.div`
  display: flex;
  width: 20%;
  padding: 0 40px;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
`;
const Icon = styled.div`
  width: 18px;
  cursor: pointer;
`;
const Edit = styled.div`
  background-color: var(--darkgrey);
  border-radius: 8px;
  border: 1px solid var(--bordercolor);
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--lightblue);
`;

const Image = styled.div`
  width: 30%;
  background-color: var(--darkgrey);
  border-radius: 8px;
  border: 1px solid var(--bordercolor);
  padding: 10px 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 60%;
    display: block;
  }
`;
const Status = styled.div`
  width: 20%;
  border-radius: 8px;
  border: 1px solid var(--bordercolor);
  padding: 10px 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.active === true
      ? "background-color: var(--lightblue);color:#fff;"
      : "background-color: var(--darkgrey);color:var(--lightblue);"};
`;

const Section = styled.div``;

const Cover = styled.div`
  display: flex;
  margin: 15px 0px;
`;

const Label = styled.div`
  color: var(--textcolor);
  width: 40%;
`;
const Input = styled.input`
  padding: 10px 20px;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;

const TextArea = styled.textarea`
  padding: 10px 20px;
  width: 100%;
  height: 120px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;

const PreviewImg = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  width: 100%;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;

const Submit = styled.div`
 display: flex;
 margin: 15px 0px;
 align-items: flex-end;
 justify-content:flex-end;
`;

const SubmitButton = styled.button`
   background-color: var(--lightblue);
    color: var(--blue);
    width: 150px;
    height: 40px;
    font-family: "mont-medium";
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;
`;
