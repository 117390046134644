import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Context } from "../../context/Store";
import instance from "../../../axios/axios";
import { addBannerApi, addMasterPlanPoints, addTestimonialApi, viewAmenities } from "../../../axios/api";
import Swal from "sweetalert2";
import { amenityData } from "../../general/dummyData";


function AddMasterPlanPointModal({ isModal, setModal, type, id }) {
  const initalState = {
    name:"",
  }
   const [formData, setformData] = useState(initalState);
   const [error, seterror] = useState("");
   const [img, setimg] = useState('')
   const [selectedImg, setselectedImg] = useState(null);
   const [amenities, setamenities] = useState([]);
   let validationError = false;



   useEffect(() => {
    instance
    .get(viewAmenities+id)
     .then((response)=>{
       const { StatusCode, data } = response.data.app_data;
      
       if(StatusCode === 6000){
       if(data.length === 0){
           setamenities([])
       }else{
         setamenities(data)
       }

       }else{
         setamenities([])
       }
     })
 }, [isModal]);

  // -----------------Add program------------------------
  const assignHandler = () => {
      const form = new FormData();
      form.append("tab_points",JSON.stringify([0,0]))
      form.append("desktop_points",JSON.stringify([0,0]))
      form.append("mobile_points",JSON.stringify([0,0]))
      form.append("project",id)

      if(formData?.name === ""){
        seterror(`name - this field is required`);
        validationError=true;
            setTimeout(() => {
              seterror("");
            }, 3000);
      }else{
        form.append('name',formData?.name)

      }

      if(img === '' && selectedImg === null){
        validationError=true;
        seterror(`select of choose a image`);
        setTimeout(() => {
          seterror("");
          validationError=false;
        }, 3000);
      }else{
        form.append("amenity",img);
        if(selectedImg){
          form.append('image',selectedImg);
        }else{
          form.append('image',null);
        }
      }

      if(!validationError){
        instance.post(addMasterPlanPoints,form)
        .then((response)=>{
          const { StatusCode , data} = response.data.app_data;
          if(StatusCode === 6000){
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Point Added !',
              showConfirmButton: false,
              timer: 1500,
              width:600,
            })
            setformData(initalState);
            setModal(false);
            setimg('')
            setselectedImg(null)
          }else if(StatusCode === 6001){
              seterror(data.Message)
              setTimeout(() => {
                seterror("");
              },3000);
          }
        })
      }
  };

  const handleChange = (e) =>{
       const { name} = e.target;
       validationError=false;
       if(name === "image"){
          setselectedImg(e.target.files[0]);
       }else{
        setformData({
          ...formData,
          [name] : e.target.value
        })
       }
  };


  return (
    <Container className={isModal && "active"}>
      <Overlay onClick={() => setModal(false)}></Overlay>
      <Modal>
        <Containers>
           {
            error && <Error>{error}</Error>
           }
        <p>Select a Image (1000 x 563) or choose</p>
        <AmenitiyImages>
            {
                amenities.map((obj)=>{
                    return (
                      <Card active={img === obj.id} onClick={()=>{
                        if(img === obj.id){
                            setimg('')
                        }else{
                            setimg(obj.id)
                        }
                      }}>
                        <img src={obj.picture} alt="" />
                        <p>{obj.name}</p>
                      </Card>
                    )
                })
            }
        </AmenitiyImages>
        <ImageSelect>
            <input name="image" onChange={(e)=>handleChange(e)} type="file" />
        </ImageSelect>

        <Cover>
            <Label>Name</Label>
            <Input
              type="text"
              placeholder="Enter Name"
              onChange={(e)=>handleChange(e)}
              name="name"
              value={formData?.name}
            />
        </Cover>
       
        <Covers>
            <Submit
              onClick={() => {
                assignHandler();
              }}
            >
              Submit
            </Submit>
        </Covers>

        </Containers>
      </Modal>
    </Container>
  );
}
export default AddMasterPlanPointModal;

const Container = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
  &.active {
    transform: scale(1, 1);
    backdrop-filter: blur(4px);
  }
`;
const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 1000;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 90%;
  max-width: 736px;
  max-height: 100vh;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
  z-index: 1000;
  background: #232327;
  border-radius: 5px;
  overflow-y: hidden;
  box-shadow: 0px 3px 56px #000;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  @media all and (max-width: 1380px) {
    left: 58%;
  }
  @media all and (max-width: 1280px) {
    width: 550px;
  }
  @media all and (max-width: 1080px) {
    width: 500px;
  }
  @media all and (max-width: 980px) {
    width: 450px;
  }
  @media all and (max-width: 768px) {
    width: 400px;
  }
  @media all and (max-width: 640px) {
    width: 350px;
  }
  @media all and (max-width: 480px) {
    width: 330px;
  }
  @media all and (max-width: 360px) {
    width: 300px;
  }
`;

const Containers = styled.div`
  padding: 50px 30px;
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  border-radius: 10px;
  @media all and (max-width: 640px) {
    padding: 20px;
  }
`;

const Submit = styled.div`
  background-color: var(--lightblue);
  color: #000;
  width: 150px;
  height: 40px;
  font-family: "mont-medium";
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;


const Error = styled.p`
    padding: 0;
    margin: 10px 0;
    color: var(--red);
    width: 100%;
    text-align: center;
`;


const AmenitiyImages = styled.div`
    width:100%;
    margin: 10px auto;
    display: flex;
    flex-wrap: wrap;
    gap:5px;
    height: 350px;
    overflow-y: scroll;
    scrollbar-width:10px;
    row-gap: 7px;
    &::-webkit-scrollbar{
        width:5px;
    }
    &::-webkit-scrollbar-track{
        background-color: #434343;
    }
    &::-webkit-scrollbar-thumb{
        background-color: #686868;
    }
`;

const ImageSelect = styled.div`
      margin-top: 10px;
`;


const Card = styled.div`
      width:198px;
      aspect-ratio: 16/9;
      height:150px;
      padding: 10px;
      border-radius: 5px;
      ${(props)=>props.active && 'border: 1px solid var(--lightblue);'}
      
      img{
        width:100%;
        display:block;
      }
`;

const Covers = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: end;
`;

const Cover = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  width:100%;
  margin-top: 25px;
`;
const Label = styled.div`
  color: var(--textcolor);
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
const Input = styled.input`
  padding: 10px 20px;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;



