import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import { total_counts } from "../../general/array";
import HeadTopBar from "../../includes/HeadTopBar";
import { Context } from "../../context/Store";
import { accountsConfig } from "../../../axiosConfig";
import { listCounters } from "../../../axios/api";
import instance from "../../../axios/axios";

function Dashboard() {
  const [getProgrammesCount, setProgrammeCount] = useState();

  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const access_token = user_data.access_token;
  useEffect(() => {
    
  }, []);
  useEffect(() => {
    instance.get(listCounters)
    .then((response)=>{
      const { StatusCode , data }  = response.data.app_data;
    
      if(StatusCode === 6000){
        setProgrammeCount(data);
      }else if(StatusCode === 6001){
        setProgrammeCount([]);
      }
    })
  }, []);
  return (
    <Container>
      <Wrapper className="wrapper">
        <HeadTopBar />
        <CountSection>
          <ContentTitle>Dashboard</ContentTitle>
          <Cards>
            <Card>
                <CardTitle>Total Projects </CardTitle>
                <Count>{getProgrammesCount?.project_count}</Count>
            </Card>

          </Cards>
        </CountSection>
      </Wrapper>
    </Container>
  );
}

export default Dashboard;

const Container = styled.div`
  padding: 80px 0;
`;

const Wrapper = styled.div``;
const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 35px;
  border-bottom: 1px solid #313335;
`;
const Title = styled.div``;
const Logout = styled.div`
  display: flex;
`;
const Icon = styled.div`
  color: #ccc;
  margin-right: 10px;
`;
const Span = styled.div``;
const CountSection = styled.div`
  margin-top: 30px;
`;
const ContentTitle = styled.h3`
  margin-bottom: 50px;
`;
const Cards = styled.div`
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  flex-wrap: wrap;
  gap: 20px;
`;
const Card = styled.div`
  background-color: #1b1d21;
  border: 1px solid #313335;
  width: 16%;
  padding: 30px 30px 10px;
  border-radius: 20px;
`;
const Count = styled.h1`
  color: #519bf4;
  font-size: 50px;
  font-family: "mont-medium";
`;
const CardTitle = styled.h3`
  color: #a4a5a4;
  margin-bottom: 30px;
  @media all and (max-width: 1440px) {
    font-size: 16px;
  }
`;
