import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import HeadTopBar from "../../includes/HeadTopBar";
import Nodata from "../../includes/noData/Nodata";
import AddTestmonialModal from "../../modal/testimonial/AddTestmonialModal";
import { listTestimonialApi } from "../../../axios/api";
import instance from "../../../axios/axios";
import EditTestimonialModal from "../../modal/testimonial/EditTestimonialModal";
import CircularProgress from "@mui/material/CircularProgress";



const Testimonials = () => {
  const [testimonialModal, setTestimonialModal] = useState(false);
  const [isEditModal, setEditModal] = useState(false);
  const [selecteditem, setselecteditem] = useState('');
  const [getTestimonials, setTestimonials] = useState(null);
 
  useEffect(() => {

   instance.get(listTestimonialApi)
   .then((response)=>{
    const { StatusCode, data} = response.data.app_data;
    if(StatusCode === 6000){
      setTestimonials(data)
    }else if(StatusCode === 6001){
      setTestimonials([])
    }
   });

  },[testimonialModal,isEditModal]);

  

  return (
    <Container>
      <Wrapper className="wrapper">
        <HeadTopBar />
        <ProgramSection>
          <TopSection>
            <Title>Testimonials</Title>
            <CoverTop>
              <Button onClick={() => setTestimonialModal(true)}>
                Add Testimonial
              </Button>
            </CoverTop>
          </TopSection>
          <CardTitles>
            <CardTit type="sl">Sl No</CardTit>
            <CardTit apply={true} type="name">name</CardTit>
            <CardTit apply={true} type="user">Description</CardTit>
            <CardTit apply={true} type="im">Image</CardTit>
            <CardTit apply={true} type="user">edit</CardTit>
          </CardTitles>
          <Cards>
             {
              getTestimonials === null ?
              <Loader>
                <CircularProgress/>
              </Loader> :
              (
                getTestimonials?.length === 0 ? (
                  <Nodata />
                ) : (
                  getTestimonials?.map((item, index) => (
                    <Card>
                      <Name  type="sl">{index + 1}</Name>
                      <Name apply={true}  type="name">{item.name} </Name>
                      <Name apply={true} type="name">{item.description}</Name>
                      <Name apply={true} type="name">
                        <ImageCover>
                          <img src={item.photo} alt="User-image" />
                        </ImageCover>
                      </Name>
                      <Name apply={true}
                        onClick={() => {
                          setEditModal(true)
                          setselecteditem(item)
                        }}
                        type="edit"
                        className="delete"
                      >
                        <i class="ri-edit-fill"></i>
                      </Name>
                    </Card>
                  ))
                )
              )

             }
          </Cards>
        </ProgramSection>
        <AddTestmonialModal isModal={testimonialModal} setModal={setTestimonialModal}/>
        <EditTestimonialModal isModal={isEditModal} setModal={setEditModal} item={selecteditem}/>
      </Wrapper>
    </Container>
  );

};

export default Testimonials;

const Container = styled.div`
  padding: 80px 0;
  color: var(--textcolor);
`;
const Wrapper = styled.div``;
const CoverTop = styled.div`
  display: flex;
`;
const SearchBar = styled.div`
  border: 1px solid #5a5d61;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 8px;
  margin-right: 20px;
`;
const Input = styled.input`
  background-color: transparent;
  outline: none;
  border: none;
  height: 40px;
  color: #fff;
  padding: 0 20px;
  border-radius: 8px;
`;
const SearchIcon = styled.div`
  position: relative;
  &::before {
    position: absolute;
    content: "|";
    color: #5a5d61;
    left: -10px;
  }
`;
const ProgramSection = styled.div`
  margin-top: 40px;
`;
const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const Title = styled.h3`
  color: #fff;
`;
const Button = styled.div`
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  color: var(--lightblue);
  height: 40px;
  width: 150px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Cards = styled.div``;
const Card = styled.div`
  display: flex;
  gap: 10px;
  /* justify-content: space-between; */
  margin-bottom: 30px;
`;

const Delete = styled.div`
  background-color: var(--darkgrey);
  border-radius: 8px;
  border: 1px solid var(--bordercolor);
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
`;

const CardTitles = styled.div`
  display: flex;
  /* justify-content: space-between; */
  gap: 10px;
  margin-bottom: 20px;
`;
const CardTit = styled.div`
  background-color: ${({ type }) =>
    type == "em"
      ? "transparent"
      : type == "delete"
      ? "transparent"
      : "var(--darkgrey)"};
  border: ${({ type }) =>
    type == "em"
      ? "none"
      : type == "delete"
      ? "none"
      : "1px solid var(--bordercolor)"};

  width: ${({ type }) =>
    type == "sl"
      ? "85px"
      : type == "name"
      ? "220px"
      : type == "user"
      ? "250px"
      : type == "phone"
      ? "180px"
      : type == "count"
      ? "120px"
      : type == "em"
      ? "85px"
      : type == "im"
      ? "180px"
      : type == "ems"
      ? "45px"
      : type == "delete"
      ? "45px"
      : ""};
  display: flex;
  height: 40px;
  font-size: 14px;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0px 0 10px;
  box-sizing: border-box;
  ${(props)=>props.apply && `width:22% !important;`}
`;

const Name = styled.div`
  border: ${({ type }) =>
    type == "em"
      ? "1px solid var(--bordercolor)"
      : type == "ems"
      ? "1px solid var(--bordercolor)"
      : "1px solid #646464"};
  background-color: ${({ type }) =>
    type == "em" ? "var(--darkgrey)" : type == "ems" ? "var(--darkgrey)" : ""};
  width: ${({ type }) =>
    type == "sl"
      ? "85px"
      : type == "name"
      ? "220px"
      : type == "user"
      ? "250px"
      : type == "phone"
      ? "180px"
      : type == "count"
      ? "120px"
      : type == "em"
      ? "85px"
      : type == "im"
      ? "180px"
      : type == "ems"
      ? "45px"
      : type == "edit"
      ? "180px"
      : ""};
  height: ${({ type }) =>
    type == "user" ? "150px" : type == "im" ? "150px" : "50px"};
  padding: ${({ type }) =>
    type == "ems"
      ? "0"
      : type == "sl"
      ? "0"
      : type == "count"
      ? "0"
      : "0 0 0 15px"};
  justify-content: ${({ type }) =>
    type == "ems"
      ? "center"
      : type == "sl"
      ? "center"
      : type == "name"
      ? "flex-start"
      : "center"
      };
  cursor: ${({ type }) =>
    type == "ems" ? "pointer" : type == "em" ? "pointer" : ""};

  display: flex;
  color: ${({ type }) => (type == "em" ? "#a35252" : "#ffffff8e")};
  border-radius: 8px;
  align-items: center;
  box-sizing: border-box;
  overflow: scroll;
  ${(props)=>props.apply && `width:22% !important;`}
  &::-webkit-scrollbar {
    display: none;
  }
  &.text {
    font-size: 12px;
    padding-top: 20px;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &.delete {
    color: #a35252;
    padding: 0px;
    cursor: pointer;
    font-size: 18px;
  }
  @media all and (max-width: 1440px) {
    font-size: 14px;
  }
`;
const ImageCover = styled.div`
  width: 75px;
  img {
    width: 100%;
    display: block;
  }
`;


const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;
`;
