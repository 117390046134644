import axios  from 'axios';
import { getRefreshToken } from './apiFunction';
import { refreshTokenApi } from './api';


// export const BaseUrl="http://127.0.0.1:8000/api/";
// export const BaseUrl="http://localhost:8000/api/";
// export const BaseUrl="https://skylineadmin-dev.accoladesweb.com/api/";
export const BaseUrl="https://backend.skylinehectares.com/api/";

let token = '';
if(localStorage.getItem('user_data')){
  token=JSON.parse(localStorage.getItem('user_data'))
 
}else{
  token=""
}

const instance = axios.create({
    baseURL: BaseUrl,
    headers:{
      Authorization:`Bearer ${token.access_token}`
    }
  });

  instance.interceptors.request.use(
    (config) => {
      token=JSON.parse(localStorage.getItem('user_data'))
      if (token) {
        config.headers.Authorization = `Bearer ${token.access_token}`;
      }
      return config;
    },
    (error) => {
      
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      // Handle successful responses here
      return response;
    },
    (error) => {
  
      if (error.response?.status === 401) {
          window.localStorage.clear();
          window.location.href = "/login";
      }
      return Promise.reject(error)
    }
  );
  
  export default instance;