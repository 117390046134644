import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import instance from "../../../axios/axios";
import {deleteProjectApi, editProjectApi, viewSingleProjectApi } from "../../../axios/api";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import HeadTopBar from "../../includes/HeadTopBar";
import { useNavigate, useParams } from "react-router-dom";


function EditProject() {
  const FileFields = ['plot_image','mobile_videos','computer_videos','project_logo','walkthrough_videos','master_plan',
                     'nearby_location','brochure']

  const noCheckTags = ['plot_image','mobile_videos','computer_videos','project_logo','walkthrough_videos','master_plan',
                      'nearby_location','brochure',"meta_tag","meta_description"]
  
   const initalState = {
    projectname:'',
    plot_image:'',
    mobile_videos:'',
    computer_videos:'',
    iframeurl:'',
    project_logo:'',
    walkthrough_videos:'',
    master_plan:'',
    about:'',
    nearby_location:'',
    map_location_url:'',
    location:'',
    totalplots:'',
    soldplot:0,
    status:'ongoing',
    cent:'',
    rera_number:'',
    feature:'',
    meta_tag:'',
    meta_description:'',
    brochure:'',
    slug:''
   }
   const [formData, setformData] = useState(initalState);
   const [error, seterror] = useState("");
   const [loader, setloader] = useState(false);
   const [project, setproject] = useState({});
   const [state, setstate] = useState(false);
   const { id } = useParams();
   const navigate = useNavigate();
   let validationError = false;

   useEffect(() => {
    instance
    .get(viewSingleProjectApi+id)
     .then((response)=>{
       const { StatusCode, data } = response.data.app_data;
       if(StatusCode === 6000){
       const item = data[0];
       if(data.length === 0){
           setproject({})
       }else{
        setproject(data[0])
        setformData({
         projectname:item?.projectname,
         plot_image:'',
         plot_image_preview:item?.plot_image,
         mobile_videos:'',
         mobile_videos_preview:item?.mobile_videos,
         computer_videos:'',
         computer_videos_preview:item?.computer_videos,
         iframeurl:item?.iframeurl,
         project_logo:'',
         project_logo_preview:item?.project_logo,
         walkthrough_videos:'',
         walkthrough_videos_preview:item?.walkthrough_videos,
         master_plan:'',
         master_plan_preview:item?.master_plan,
         about:item?.about,
         nearby_location:'',
         nearby_location_preview:item?.nearby_location,
         map_location_url:item?.map_location_url,
         location:item?.location,
         totalplots:item?.totalplots,
         soldplot:item?.soldplot,
         status:item?.status,
         cent:item?.cent,
         rera_number:item?.rera_number,
         feature:item?.feature,
         meta_tag:item?.meta_tag,
         meta_description:item?.meta_description,
         brochure:'',
         slug:item?.slug
       })
       }

       }else{
         setproject({})
       }
     })
    
   }, [id,state])



  // -----------------Add program------------------------
  const assignHandler = () => {
     const form = new FormData();

      Object.entries(formData).forEach((obj)=>{
        
        
        if(obj[1]===""){
          if(!noCheckTags.includes(obj[0])){
               validationError = true
               seterror(`${obj[0]} - this field is required`);
               setTimeout(() => {
                  seterror("");
                }, 3000);
                }
        }else{
          form.append(obj[0],obj[1]);
        }
      })
      
      if(!validationError){
        setloader(true)
        instance.put(editProjectApi+project?.id,form)
        .then((response)=>{
          const { StatusCode , data} = response.data;
          setloader(false)
          if(StatusCode === 6000){
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Project Details updated !',
              showConfirmButton: false,
              timer: 1500,
              width:600,
            })
            setformData(initalState);
            setstate(!state);
          }else if(StatusCode === 6001){
              seterror(data.message)
              setTimeout(() => {
                seterror("");
              },3000);
          }
        })
      }
  };
  
  const handleChange = (e) =>{
       const { name} = e.target;
       validationError=false;
       
       if(!FileFields.includes(name)){
        setformData({
            ...formData,
            [name] : e.target.value
          })
       }else{
        const field = `${name}_preview`
        if(e.target.files[0]){
          setformData({
            ...formData,
            [name] : e.target.files[0],
            [field]:URL.createObjectURL(e.target.files[0]),
          })
        }else{
          setformData({
            ...formData,
            [name] : e.target.files[0],
            [field]:"",
          })
        }
       }
  };

  const handleDelete = ()=>{
    Swal.fire({
      title: 'Do you want to delete the project?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        instance.delete(deleteProjectApi+project?.id)
        .then((response)=>{
          const { StatusCode , data} = response.data.app_data;
          if(StatusCode === 6000){
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Project Deleted !',
              showConfirmButton: false,
              timer: 1500,
              width:600,
            })
            setformData(initalState);
            navigate('/projects/')
          }else if(StatusCode === 6001){
              seterror(data.Message)
              setTimeout(() => {
                seterror("");
              },3000);
          }
        })
      }
    })
  }


  return (
    <Container>
      <Wrapper className="wrapper">
        <HeadTopBar />
        <ProgramSection>
          <TopSection>
            <Title>Edit - Project</Title>
            <Button type={"delete"}
              onClick={() => {
                 handleDelete()
              }}
            >
              Delete
            </Button>
            <Button
              onClick={() => {
                navigate('/amenities/'+project?.id)
              }}
            >
              Amenites
            </Button>
            <Button
              onClick={() => {
                navigate('/master-plan-points/'+project?.id)
              }}
            >
              Master Plan Points
            </Button>
          </TopSection>
          <Containers>
          <Cover>
            <Label>Project Name</Label>
            <Input
              type="text"
              placeholder="Enter Project name"
              onChange={(e)=>handleChange(e)}
              name={"projectname"}
              value={formData.projectname}
            />
          </Cover>
          <Cover>
            <Label>About</Label>
            <TextArea
              name={"about"}
              onChange={(e)=>handleChange(e)}
              value={formData.about}
            ></TextArea>
          </Cover>
          <Cover>
            <Label>Banner Video -Laptop <br /> (1920 x 1080)</Label>
            <CoverInput>
              <Preview>
                 <video  controls src={formData?.computer_videos_preview}></video>
              </Preview>
              <Input
                type="file"
                name={"computer_videos"}
                onChange={(e)=>handleChange(e)}
              />
            </CoverInput>
          </Cover>
          <Cover>
            <Label>Banner Video - Mobile <br /> (1080 x 1200)</Label>
            <CoverInput>
              <Preview>
                 <video  controls  src={formData?.mobile_videos_preview}></video>
              </Preview>
              <Input
                type="file"
                name={"mobile_videos"}
                onChange={(e)=>handleChange(e)}
              />
            </CoverInput>
          </Cover>
          <Cover>
            <Label>360 iframe url</Label>
            <TextArea
              name="iframeurl"
              onChange={(e)=>handleChange(e)}
              value={formData.iframeurl}
            ></TextArea>
          </Cover>
          <Cover>
            <Label>project Logo <br /> (2363 x 2246)</Label>
            <CoverInput>
             <Preview bg={"white"}>
                <img src={formData?.project_logo_preview}></img>
              </Preview>
              <Input
                type="file"
                name={"project_logo"}
                onChange={(e)=>handleChange(e)}
              />
            </CoverInput>
          </Cover>
          <Cover>
            <Label>walkthrough Video <br /> (720 x 406 )</Label>
            <CoverInput>
             <Preview>
                <video  controls  src={formData?.walkthrough_videos_preview}></video>
              </Preview>
              <Input
                type="file"
                name={"walkthrough_videos"}
                onChange={(e)=>handleChange(e)}
              />
            </CoverInput>
          </Cover>
          <Cover>
            <Label>Master Plan Image <br /> (1106 x 825)</Label>
            <CoverInput>
              <Preview bg={"white"}>
                <img src={formData?.master_plan_preview}></img>
              </Preview>
              <Input
                type="file"
                name={"master_plan"}
                onChange={(e)=>handleChange(e)}
              />
            </CoverInput>
          </Cover>
          <Cover>
            <Label>Nearby Location Image <br /> (1256 x 1298)</Label>
            <CoverInput>
            <Preview bg={"white"}>
                <img src={formData?.nearby_location_preview}></img>
            </Preview>
              <Input
                type="file"
                name={"nearby_location"}
                onChange={(e)=>handleChange(e)}
              />
            </CoverInput>
          </Cover>
          <Cover>
            <Label>Project Image <br /> (423 x 365)</Label>
            <CoverInput>
              <Preview>
                <img src={formData?.plot_image_preview}></img>
              </Preview>
              <Input
                type="file"
                name={"plot_image"}
                onChange={(e)=>handleChange(e)}
              />
            </CoverInput>
          </Cover>
          <Cover>
            <Label>Project Location Map iframe code</Label>
            <TextArea
              name={"map_location_url"}
              onChange={(e)=>handleChange(e)}
              value={formData.map_location_url}
            ></TextArea>
          </Cover>
          <Cover>
            <Label>Project Location</Label>
            <Input
              type="text"
              placeholder="Enter the Project location and district by seperating with a ',' "
              onChange={(e)=>handleChange(e)}
              name={"location"}
              value={formData.location}
            />
          </Cover>
          <Cover>
            <Label>Total Plots</Label>
            <Input
              type="number"
              placeholder="Enter Total Plots count"
              onChange={(e)=>handleChange(e)}
              name={"totalplots"}
              value={formData.totalplots}
            />
          </Cover>
          {/* <Cover>
            <Label>Sold Plots</Label>
            <Input
              type="number"
              placeholder="Enter Sold Plots count"
              onChange={(e)=>handleChange(e)}
              name={"soldplot"}
              value={formData.soldplot}
            />
          </Cover> */}
          <Cover>
            <Label>Brochure</Label>
            <CoverInput>
              <Input
                type="file"
                name={"brochure"}
                onChange={(e)=>handleChange(e)}
              />
                {
                  formData?.brochure === '' && <p>{project?.brochure?.split('/').pop()}</p> 
                }
              
            </CoverInput>
          </Cover>
          <Cover>
            <Label>Cent range</Label>
            <Input
              type="text"
              placeholder="Enter cent range"
              onChange={(e)=>handleChange(e)}
              name={"cent"}
              value={formData.cent}
            />
          </Cover>
          <Cover>
            <Label>Rera Number</Label>
            <Input
              type="text"
              placeholder="Enter Rera Number"
              onChange={(e)=>handleChange(e)}
              name={"rera_number"}
              value={formData.rera_number}
            />
          </Cover>
          <Cover>
            <Label>Feature</Label>
            <Input
              type="text"
              placeholder="Enter Feature"
              onChange={(e)=>handleChange(e)}
              name={"feature"}
              value={formData.feature}
            />
          </Cover>
          <Cover>
            <Label>meta tag</Label>
            <Input
              type="text"
              placeholder="Enter meta tag"
              onChange={(e)=>handleChange(e)}
              name={"meta_tag"}
              value={formData.meta_tag}
            />
          </Cover>
          <Cover>
            <Label>meta description</Label>
            <Input
              type="text"
              placeholder="Enter meta description"
              onChange={(e)=>handleChange(e)}
              name={"meta_description"}
              value={formData.meta_description}
            />
          </Cover>
          <Cover>
            <Label>Url - slug</Label>
            <Input
              type="text"
              placeholder="Enter url slug"
              onChange={(e)=>handleChange(e)}
              name={"slug"}
              value={formData.slug}
            />
          </Cover>
          {/* <Cover>
            <Label>Status</Label>
            <Select name="status" onChange={(e)=>handleChange(e) }>
                {
                    formData?.status === "ongoing" ? (
                        <option selected  value="ongoing">Ongoing</option>
                    ):(
                        <option value="ongoing">Ongoing</option>
                    )
                }
                {
                    formData?.status === "completed" ? (
                        <option selected  value="completed">completed</option>
                    ):(
                        <option value="completed">completed</option>
                    )
                }
            </Select>
          </Cover> */}


          <Covers>
            <Submit
              onClick={() => {
                assignHandler();
              }}
            >
              {
                loader ? <CircularProgress/> : "Update"
              }
            </Submit>
          </Covers>
          {
            error && <Error>{error}</Error>
          }
        </Containers>
        </ProgramSection>
      </Wrapper>
    </Container>
  );
}
export default EditProject;



const Container = styled.div`
  padding: 80px 0;
  color: var(--textcolor);
`;
const Wrapper = styled.div``;

const ProgramSection = styled.div`
  margin-top: 40px;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.h3`
  color: #fff;
`;

const Button = styled.div`
  background-color: var(--lightblue);
  border: 1px solid var(--bordercolor);
  ${(props)=>props.type === 'delete' && `
      background-color:var(--red);
  `}
  color: #fff;
  height: 40px;
  width: 160px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Containers = styled.div`
  padding: 50px 80px;
  background-color: transparent;
  border: 1px solid var(--bordercolor);
  border:none;
  @media all and (max-width: 640px) {
    padding: 20px;
  }
`;

const Covers = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: end;
`;
const Cover = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
`;
const Label = styled.div`
  color: var(--textcolor);
  width: 40%;
`;
const Input = styled.input`
  padding: 10px 20px;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;
const TextArea = styled.textarea`
  padding: 10px 20px;
  width: 100%;
  height: 120px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;
const Submit = styled.div`
  background-color: var(--lightblue);
  color: #000;
  width: 150px;
  height: 40px;
  font-family: "mont-medium";
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    width: 25px !important;
    height: 25px !important;
    color: white;
  }
`;
const Sub = styled.div`
  display: flex;
  width: 60%;
`;

const Error = styled.p`
    padding: 0;
    margin: 10px 0;
    color: var(--red);
    width: 100%;
    text-align: center;
`;

const Preview = styled.div`
    width: 100%;
    margin-bottom: 10px;
    ${(props)=>props.bg && 'background-color:#fff;'}
    img{
      width: 100%;
      display: block;
    }
    video{
      width: 100%;
      display: block;
    }
`;

const Select = styled.select`
  padding: 10px 20px;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;
const CoverInput = styled.div`
  width: 100%;
  p{
    margin:0;
    padding:0;
  }
`;

