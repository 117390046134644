import { BaseUrl } from "./axios";

export const bannerListApi = BaseUrl+"home/view-mainbanner/";
export const addBannerApi = BaseUrl+"home/add-mainbanner/";
export const editBannerApi = BaseUrl+"home/edit-mainbanner/";
export const deleteBannerApi = BaseUrl+"home/delete-mainbanner/";
export const BannerStatusToggleApi = BaseUrl+"home/hide-mainbanner/";

export const listEnquiry = BaseUrl+"projects/view-enquiry/";
export const printEnquiry = BaseUrl+"projects/download-enquiry/";

export const viewHomeAbout = BaseUrl+"home/view-about/";
export const updateHomeAbout = BaseUrl+"home/edit-about/";


export const chiefLoginApi = BaseUrl+"general/chief-login/";
export const refreshTokenApi = BaseUrl+"token/refresh/";

export const addBlogApi = BaseUrl+"home/add-blog/";
export const listBlogApi = BaseUrl+"home/view-blog/";
export const deleteBlogApi = BaseUrl+"home/delete-blog/";
export const editBlogApi = BaseUrl+"home/edit-blog/";

export const addTestimonialApi = BaseUrl+"home/add-testimonials/";
export const listTestimonialApi = BaseUrl+"home/list-testimonials/";
export const editTestimonialApi = BaseUrl+"home/edit-testimonials/";
export const deleteTestimonialApi = BaseUrl+"home/delete-testimonials/";

export const listAwardsApi = BaseUrl+"home/view-award/";
export const addAwardApi = BaseUrl+"home/add-award/";
export const editAwardApi = BaseUrl+"home/edit-award/";
export const deleteAwardApi = BaseUrl+"home/delete-award/";


export const addAboutFeature = BaseUrl+"home/add-about-content-feature/";
export const listAboutFeature = BaseUrl+"home/view-about-content-feature/";
export const deleteAboutFeature = BaseUrl+"home/delete-about-content-feature/";


export const viewContactDetails = BaseUrl+"home/view-contactdetails/";
export const editContactDetails = BaseUrl+"home/edit-contactdetails/";


export const viewAboutDetails = BaseUrl+"home/view-about-content/";
export const editAboutDetails = BaseUrl+"home/edit-about-content/";


export const addProjectApi = BaseUrl+"project/add-project/";
export const viewProjectApi = BaseUrl+"project/view-project/";
export const editProjectApi = BaseUrl+"project/edit-project/";
export const viewSingleProjectApi = BaseUrl+"project/view-single-project/";
export const deleteProjectApi = BaseUrl+"project/delete-project/";

export const viewAmenities = BaseUrl+"projects/view-amenities/";
export const addAmenities = BaseUrl+"projects/add-amenities/";
export const editAmenities = BaseUrl+"projects/edit-amenities/";
export const deleteAmenities = BaseUrl+"projects/delete-amenities/";

export const listCounters = BaseUrl+"home/count-enquiry/";

export const listMasterPlanPoints = BaseUrl+"project/view-masterplan-points/";
export const addMasterPlanPoints = BaseUrl+"project/add-masterplan-points/";
export const deleteMasterPlanPoint = BaseUrl+"project/delete-masterplan-points/";
export const editMasterPlanPoint = BaseUrl+"project/edit-masterplan-points/";

export const listMasterPlanArrows = BaseUrl+"project/view-arrow-points/";
export const updateMasterPlanArrows = BaseUrl+"project/edit-arrow/";







